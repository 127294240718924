.contactPage {
  height: 100lvh;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
}

.contact-title {
  text-align: center;
}

.form-container {
  padding: 1em;
  width: 90%;
  margin: auto;
}

form {
  width: 50%;
  margin: auto;
}

.form-item {
  margin-bottom: 1em;
  border: 0;
  width: 100%;
  height: 7vh;
  padding: 7px;
}

.name-email-group {
  align-items: center;
}

.form-item-textbox {
  border: 0;
  height: 20vh;
  width: 100%;
  margin-bottom: 1em;
  padding: 7px;
}

.contact-title {
  padding-top: 2em;
  padding-bottom: 2em;
}

/* Button CSS */
.button-21 {
  align-items: center;
  appearance: none;
  background-color: #332D2D;
  background-image: linear-gradient(1deg, #787171, #332D2D 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -20px -20px;
}

.button-21:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

@media (max-width: 780px) {
  .form-item {
    width: 100%;
  }
    
  form {
    width: 100%;
    margin: auto;
  }
}