.cv-container {
  margin: 2em;
  height: 100%;
}

.title {
  text-align: center;
  width: 100%;
}

.projectItem {
  margin-bottom: 3%;
  height: 300px;
}

.projectImageLeft {
  max-width: 40%;
  height: 100%;
  margin-left: 0px;
  mask-image: linear-gradient(to left, rgba(0,0,0,0) 0%, 70%, rgba(0,0,0,1) 100%);
  margin-left: -1em;

}

.leftText {
  margin-left: 1em;
  margin-top: 3em;
  margin-bottom: 3em;
  margin-left: 2em;
  margin-right: 2em;
  padding: 1em;
}

.projectImageRight {
  max-width: 40%;
  height: 100%;
  margin-left: 0px;
  mask-image: linear-gradient(to right, rgba(0,0,0,0) 0%, 50%, rgba(0,0,0,1) 100%);
  margin-right: -0.77rem;
}

.rightText {
  margin-left: 1em!important;
  margin-top: 3em;
  margin-bottom: 3em;
  margin-right: auto;
  padding: 1em;
}

/* Button CSS */
.button-21 {
  align-items: center;
  appearance: none;
  background-color: #4c5181;
  background-image: linear-gradient(1deg, #868cb3, #3b4c89 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -20px -20px;
}

.button-21:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

/* MOBILE VIEW */
.cv-container-mobile {
  max-width: 100%!important;
  width: 100%;
}

.title-mobile {
  margin-top: 1.5em;
  text-align: center;
}

.projectImageMiddle {
  max-width: 100%;
  height: 100%;
  margin-left: 0px;
  mask-image: linear-gradient(to top, rgba(0,0,0,0) 0%, 50%, rgba(0,0,0,1) 100%);
}

.middleText {
  margin-top: 0;
  margin-bottom: 3em;
  margin-right: auto;
  padding: 1em;
}

.pageContent {
  max-width: 100%;
  margin: auto;
}
