.cv-container {
  margin: 2em;
}

.title {
  text-align: center;
  width: 100%;
}

@media (max-width: 600px) {
  .button-21 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}