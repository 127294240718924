.blogPost {
  min-height: 100vh;
  height: 100%;
  width: 70%;
  margin: auto;
  border-right: 1px solid rgba(189, 189, 189, 0.438);
  border-left: 1px solid rgba(189, 189, 189, 0.438);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.blogpost-title {
  margin-top: 1em;
  margin-bottom: 1em;
  white-space: initial;
}

.blogpost-subtitle {
  margin: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  white-space: initial;
}

.blogpost-text {
  margin-left: 1em;
  max-width: 100%;
  white-space: initial;
}

@media (max-width: 700px) {
  .blogPost {
    width: 100%;
    border-right: none;
    border-left: none;
  }

  .blogpost-title {
    margin-top: 2.5em;
  }
}

a {
  color: black!important;
}

.blogbase-link {
  position:fixed;
  margin: 0.5em;
  margin-left:0;
}

@media(max-width: 700px) {
  .blogbase-link {
    margin-top: 1.1em;
    margin-left: 3.5em;
  }
}