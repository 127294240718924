ul {
  text-align: left;
  padding: 0!important;
}

.split-experience {
  text-align: center;
  position: relative;
  margin: 0;
  flex: 1!important;
  min-height: 100vh;
}

.split-experience:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 2em;
  bottom: 2em;
  right: 0;
  width: 1px;
  background-color: rgb(173, 173, 173); 
}

.title-homelower {
  margin-top: 1em!important;
  text-align:center;
}

h1{
  padding:0;
  margin: 0;
}

.mobile-div {
  margin-top: 2.5em!important;
}

.mobile-experience{
  max-width: 100%;
}


.li-main {
  list-style-type: none;
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 1px solid rgba(189, 189, 189, 0.438);
  transition: 0.3s;
  cursor: pointer;
}

.li-main-last {
  border-bottom: 1px solid rgba(189, 189, 189, 0.438);
}

.li-main:hover {
  list-style-type: none;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
}

.li-main-clicked {
  padding-left: 1em;
  border-bottom: none;
  background-image: linear-gradient(to right, rgba(189, 189, 189, 0.438), #e3f3f1 3%);
  border-bottom: 1px solid rgba(189, 189, 189, 0.438);
}

@keyframes slideIn {
  from {
    transform: translateY(-10px); /* Move the item up initially */
    opacity: 0; /* Start with 0 opacity */
  }
  to {
    transform: translateY(0); /* Move the item to its normal position */
    opacity: 1; /* Fully visible */
  }
}

.li-sub {
  list-style-type: circle;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  max-height: 1000px!important;
  transition: max-height 0.5s ease-in-out!important;
  animation: slideIn 0.5s ease-in-out;
}

.li-main-clicked .li-sub {
  max-height: 1000px; /* Show the sub-items when the parent is clicked */
}