.thankyoupage {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thankstitle {
  margin-bottom: 10%;
  width: 30%;
}

@media (max-width: 700px) {
  .thankstitle {
    margin-bottom: 10%;
    width: 90%;
  }
}