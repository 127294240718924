/* GENERAL STRUCTURING OF PAGES + NAVBAR */
.pageContentNoSidebar {
  height: 100%;
  background-color: #e3f3f1;
}

.pageContentWithSidebar {
  height: 100%;
  padding-left: 2em;
  /* background-color: rgba(225, 216, 216, 0.269); */
  background-color: #e3f3f1;
}

@media (min-width: 768px) {
  .pageContentWithSidebar {
      margin-left: 25%;
      width: 75%!important;
  }
}

@media (min-width: 1200px) {
  .pageContentWithSidebar {
    margin-left: 16.66666667%;
    width: 83.33333333%!important;
  }
}

