.homeContent{
  height: 100%;
}

.homePage {
  height: 100lvh;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

.homePageContent {
  margin: auto;
  vertical-align: middle;
}

.inside-content {
  width: 80%;
  margin:auto;
  padding-top: 70px;
}

/* Inside the main small box of personal info */
.profilePicture {
  border-radius: 50%;
  width: 30%;
  height: auto;
}

.textContent {
  margin: 3em;
}

.homeInfoTitle {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  
}

.homeInfoText {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  
}

.downArrow {
  bottom: 0!important;
}

/* Other content outside main page */

.otherContent{
  height: 100%;
}

.split-experience {
  margin-left: 1em;
  padding: 2em;
  width: 33%;
}


/* Button CSS */
.button-21 {
  align-items: center;
  appearance: none;
  background-color: #332D2D;
  background-image: linear-gradient(1deg, #787171, #332D2D 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -20px -20px;
}

.button-21:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

/* PHONE STUFF */
.textContentMobile {
  text-align: center;
}

.mobile-align {
  margin: auto;
  display: flex;
  margin-bottom: 1.5em;
}