.navbar-content {
  position: -webkit-sticky;
  position: sticky;
  height: 100lvh;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  max-width: 100%;
  background-color: rgb(0, 19, 51);
}

.link-fonts {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  
}

.link-hover:hover {
  color: rgb(154, 137, 137)!important;
  max-width: 100%;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
}

.socialIcons {
  top: 0;
}

.bold-text-nav {
  font-weight: 1000;
  color: rgba(203, 255, 251, 0.541);  
   /* margin-left: 0.5em; */
}