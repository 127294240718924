a {
  text-decoration: none!important;
}

.blogPage {
  margin: 2em;
  height: 100%;
}

.title-blog {
  text-align: center;
  width:100%;
}

.imageBlogPost{
  width: 50%;
}

.imageInPost {
  width: 100%;
  border: 1px solid gray;
  border-radius: 20px;
  transition: filter 0.3s ease-out 100ms!important;
  margin-top: 1.5em;
}

.imageInPost:hover {
  filter: grayscale(50%);
}

.imageBlogPostText {
  bottom: 1em;
  left: 1em;
  text-align: left;
  color:black;
}


.textBlogPost {
  border: 1px solid gray;
  border-radius: 20px;
  width: 50%;
  margin-top: 1.5em;
  text-align: left;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: black;
  transition: background-color 0.3s ease-out 100ms!important;
}

.textBlogPost:hover {
  background-color: rgba(179, 179, 180, 0.516);
}

@media (max-width: 768px) {
  .imageBlogPost{
    width: 100%;
  }

  .textBlogPost {
    width: 100%;
  }
}